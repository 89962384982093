import { fr, en } from '@formkit/i18n';
import { DefaultConfigOptions } from '@formkit/vue';
import { genesisIcons, applicationIcons, icons  } from '@formkit/icons';

import { generateClasses } from '@formkit/themes';
import myTailwindTheme from './tailwind-form-theme';

import { createAutoAnimatePlugin } from '@formkit/addons';
import i18n from '@/i18n';

const config: DefaultConfigOptions = {
  locales: { fr, en },
  locale: i18n.global.locale.value,
  icons: {
    ...genesisIcons,
    ...applicationIcons,
    ...icons
  },
  
  config: {
    classes: generateClasses(myTailwindTheme),
  },
}

const isCheckboxAndRadioMultiple = (node) => (node.props.type === 'checkbox' || node.props.type === 'radio') && node.props.options

function addAsteriskPlugin (node) {
  node.on('created', () => {
    const isRequired = node.props.parsedRules.some(rule => rule.name === 'required');
    if (!isRequired) return
    
    const isMultiOption = isCheckboxAndRadioMultiple(node)
    
    // if we're going to modify the schema then we need
    // to update the schemaMemoKey so we don't get an 
    // invalid cached schema.
    node.props.definition.schemaMemoKey = `required_${isMultiOption ? 'multi_' : ""}${node.props.definition.schemaMemoKey}`
    
    const schemaFn = node.props.definition.schema;
    node.props.definition.schema = (sectionsSchema = {}) => {      
      if (isRequired) {
        if(isMultiOption) {
          sectionsSchema.legend = {
            children: ['$label', '*']
          }
        } else {
          sectionsSchema.label = {
            children: ['$label', '*']
          }
        }
      }
      return schemaFn(sectionsSchema);
    }
  })
}

config.plugins = [addAsteriskPlugin, createAutoAnimatePlugin()]

export default config