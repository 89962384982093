Header to be used only for non logged-in users

<script setup lang="ts">
import { ref } from 'vue';
import LoginModal from '@/components/LoginModal.vue';
import { FwbButton, FwbNavbar, FwbNavbarLogo, FwbNavbarCollapse, FwbNavbarLink, FwbDropdown, FwbListGroup, FwbListGroupItem } from 'flowbite-vue';
import imageUrl from '@/assets/teachtech_gradient__blue_purp.svg';
import i18n from '@/i18n';

function setI18nLocale(locale: "fr" | "en") {
  i18n.global.locale.value = locale
}

const showSignup = ref(false);
const isShowLoginModal = ref(false);

function handleShowModal(showSignupModal:boolean) {
  showSignup.value = showSignupModal;
  isShowLoginModal.value = true; 
}
</script>

<template>

  <fwb-navbar class="font-medium" sticky>
    <template #logo>
      <router-link :to="{ name: 'Landing' }">
        <fwb-navbar-logo alt="TeachTech logo" :image-url="imageUrl" link="#" />
      </router-link>
    </template>
    <template #default="{ isShowMenu }">
      <fwb-navbar-collapse :is-show-menu="isShowMenu" class="md:hidden">
        <fwb-navbar-link link="#" @click="handleShowModal(true)" class="border-b-2">
          <div class="flex gap-3">
            <svg class="w-4 h-4 self-center text-gray-800 dark:text-white" aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd"
                d="M9 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H7Zm8-1a1 1 0 0 1 1-1h1v-1a1 1 0 1 1 2 0v1h1a1 1 0 1 1 0 2h-1v1a1 1 0 1 1-2 0v-1h-1a1 1 0 0 1-1-1Z"
                clip-rule="evenodd" />
            </svg>
            <p>{{ $t('sign-up-form.sign-up') }}</p>
          </div>
        </fwb-navbar-link>
        <fwb-navbar-link link="#" @click="handleShowModal(false)" class="border-b-2">
          <div class="flex gap-3">
            <svg class="w-4 h-4 self-center text-gray-800 dark:text-white" aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M16 12H4m12 0-4 4m4-4-4-4m3-4h2a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3h-2" />
            </svg>
            <p>{{ $t('common.sign-in') }}</p>
          </div>
        </fwb-navbar-link>


        <!-- Become a Tutor Link -->
        <router-link :to="{ name: 'TutorSignup' }">
    <fwb-navbar-link link="#" class="border-b-2">
      <div class="flex gap-3">
        <svg class="w-4 h-4 self-center text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
          <path fill-rule="evenodd" d="M7 6a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2h-2v-4a3 3 0 0 0-3-3H7V6Z" clip-rule="evenodd"/>
          <path fill-rule="evenodd" d="M2 11a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-7Zm7.5 1a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Z" clip-rule="evenodd"/>
          <path d="M10.5 14.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"/>
        </svg>
        <p>{{ $t('nav.monetize-your-passion') }}</p>
      </div>
    </fwb-navbar-link>
  </router-link>
  <fwb-navbar-link link="#">
          <div v-if="i18n.global.locale.value == 'en'" class="flex gap-3" @click="setI18nLocale('fr')">
            <svg class="w-4 h-4 self-center dark:text-white cursor-pointer" aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m13 19 3.5-9 3.5 9m-6.125-2h5.25M3 7h7m0 0h2m-2 0c0 1.63-.793 3.926-2.239 5.655M7.5 6.818V5m.261 7.655C6.79 13.82 5.521 14.725 4 15m3.761-2.345L5 10m2.761 2.655L10.2 15" />
            </svg>
            <p>Français</p>
          </div>
          <div v-if="i18n.global.locale.value === 'fr'" class="flex gap-3" @click="setI18nLocale('en')">
            <svg class="w-4 h-4 self-center dark:text-white cursor-pointer" aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m13 19 3.5-9 3.5 9m-6.125-2h5.25M3 7h7m0 0h2m-2 0c0 1.63-.793 3.926-2.239 5.655M7.5 6.818V5m.261 7.655C6.79 13.82 5.521 14.725 4 15m3.761-2.345L5 10m2.761 2.655L10.2 15" />
            </svg>
            <p>English</p>
          </div>
        </fwb-navbar-link>
      </fwb-navbar-collapse>
    </template>
    <template #right-side>
      <div class="flex items-center space-x-4">
        <!-- Log In Button -->
        <fwb-button size="md" @click="handleShowModal(true)" gradient="purple-blue">
          {{ $t('sign-up-form.sign-up') }}
        </fwb-button>

        <!-- Log In Button -->
        <fwb-button size="sm" @click="handleShowModal(false)" outline gradient="purple-blue">
          {{ $t('common.sign-in') }}
        </fwb-button>

        <!-- Become a Tutor Button -->
        <fwb-button size="sm" outline gradient="purple-blue">
          <router-link :to="{ name: 'TutorSignup' }">
            {{ $t('nav.monetize-your-passion') }}
          </router-link>
        </fwb-button>

        <!-- Dropdown Icon -->
        <fwb-dropdown placement="left">
          <template #trigger>
            <svg class="w-5 h-5 align-middle dark:text-white cursor-pointer" aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m13 19 3.5-9 3.5 9m-6.125-2h5.25M3 7h7m0 0h2m-2 0c0 1.63-.793 3.926-2.239 5.655M7.5 6.818V5m.261 7.655C6.79 13.82 5.521 14.725 4 15m3.761-2.345L5 10m2.761 2.655L10.2 15" />
            </svg>
          </template>
          <fwb-list-group>
            <fwb-list-group-item @click="setI18nLocale('en')" class="cursor-pointer hover:bg-gray-200">
              <p class="hover:bg-gray-200 p-1">English</p>
            </fwb-list-group-item>
            <hr class="border-t-4">
            <fwb-list-group-item @click="setI18nLocale('fr')" class="cursor-pointer hover:bg-gray-200">
              <p class="hover:bg-gray-200 p-1">Français</p>
            </fwb-list-group-item>
          </fwb-list-group>
        </fwb-dropdown>
      </div>
    </template>
  </fwb-navbar>

  <transition name="modal">
    <LoginModal v-if="isShowLoginModal" @close="isShowLoginModal = false" :show-signup="showSignup" />
  </transition>
</template>

<style scoped>
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.8s;
}

.modal-enter,
.modal-leave-to {
  opacity: 0;
}
</style>
