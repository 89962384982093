import { createI18n } from 'vue-i18n'
import fr from "./locales/fr.json";
import en from "./locales/en.json";

const authorizedLanguages = ['en', 'fr'];
// use of substring because language can be followed by country code ex: fr-CA, en-US, etc....
const navigatorLang = navigator.language.substring(0,2);
const locale = authorizedLanguages.includes(navigatorLang) ? navigatorLang : 'en';

const i18n = createI18n({
    legacy: false, // to use composition api
    locale: locale,
    fallbackLocale: "en",
    messages: { fr, en }
})

export default i18n