import { createApp } from 'vue'
import { createPinia } from 'pinia'
import './assets/style.css'
import App from './App.vue'
import router from './router'
import { createHead } from '@unhead/vue'
import { createGtm } from '@gtm-support/vue-gtm';

import { plugin, defaultConfig } from '@formkit/vue'
import config from '../formkit.config.ts'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import ElementPlus from 'element-plus';

import i18n from './i18n.ts'


async function init() {
    const gaTag = "GTM-5CGHWHQH";
    const pinia = createPinia();
    const head = createHead();
    const app = createApp(App);
    app.use(pinia);
    app.use(i18n)
    app.use(router);
    // add font awesome icons to library
    library.add(faHeart);
    app.component('font-awesome-icon', FontAwesomeIcon);

    // use head for SEO meta tags
    app.use(head);

    app.use(
        createGtm({
            id: gaTag,
            vueRouter: router
          })
    )

    app.use(ElementPlus);

    app.use(plugin, defaultConfig(config));

    app.mount('#app');
}

init().catch((err) => {
    console.error(err);
});

