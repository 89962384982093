import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useAuthStore } from "@/stores/auth";
import i18n from "@/i18n";

const routes: Array<RouteRecordRaw> = [
{
      path: "/:locale(en|fr)?",
      name: "Landing",
      component: () => import("@/views/common/Landing.vue")
    },
  {
    path: "/:locale(en|fr)?/tutors",
    name: "Tutors",
    component: () => import("@/views/common/Tutors.vue"),
  },
  {
    path: "/:locale(en|fr)?/tutor/:tutorId",
    name: "Tutor",
    component: () => import("@/views/student/TutorBooking.vue"),
  },
  {
    path: "/:locale(en|fr)?/tutor-signup",
    name: "TutorSignup",
    //TODO: add routes prefixers for folder (eg: tutorRoutes="@/views/tutor")
    component: () => import("@/views/tutor/TutorSignup.vue")
  },
  {
    path: "/:locale(en|fr)?/faq",
    name: "FAQ",
    component: () => import("@/views/common/Faq.vue"),
  },
  {
    path: "/:locale(en|fr)?/contact",
    name: "Contact",
    component: () => import("@/views/common/Contact.vue"),
  },
  {
    path: "/:locale(en|fr)?/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("@/views/common/PrivacyPolicy.vue"),
  },
  {
    path: "/:locale(en|fr)?/terms",
    name: "TermsAndConditions",
    component: () => import("@/views/common/TermsAndConditions.vue"),
  },

  // protected routes below
  {
    path: "/profile",
    name: "ProfileSettings",
    component: () => {
        return import("@/views/common/ProfileSettings.vue");
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/sessions",
    name: "Sessions",
    component: () => import("@/views/common/Sessions.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/messages/:convoId?",
    name: "Messages",
    component: () => import("@/views/common/Messages.vue"),
    meta: { requiresAuth: true },
  },

  // Catch-all route for 404
  {
    path: "/:catchAll(.*)",
    component: () => import("@/views/common/404NotFound.vue"),
  },
  ];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  const supabaseAuth = localStorage.getItem("sb-qdtgkmdygvqzbnpegbjh-auth-token");

  // Initialize authentication if needed
  if (authStore.user === null && supabaseAuth !== undefined) {
    try {
      await authStore.initializeAuth();
    } catch (error) {
      console.error("Error initializing auth:", error);
    }
  }

  setLocale(to.params.locale, authStore.user?.locale);

  if ((to.path === "/" || to.path === "/tutor-signup") && authStore.user) {
    next({ name: "Tutors" });
    return;
  }
    
  if (to.meta.requiresAuth && !authStore.user) {
    next({ name: "Landing" });
    return;
  }

  next();
});

function setLocale(localeFromPath:string|undefined, userLocale:string|undefined) {
  const supportedLocales = ['en', 'fr'];
  const defaultLocale = 'en';

  let locale;

  // Use profile locale if user is logged in
  if (userLocale) {
    locale = userLocale;
    i18n.global.locale.value = locale;
  }  // Handle SEO: If a locale is in the route but doesn't match the user locale, allow it
  else if (localeFromPath && supportedLocales.includes(localeFromPath)) {
    locale = localeFromPath;
    i18n.global.locale.value = locale;
  }
  else {
    // Otherwise, use browser locale for non-logged-in users
    const browserLocale = localStorage.getItem('locale') ?? navigator.language.split('-')[0].substring(0, 2);
    locale = supportedLocales.includes(browserLocale) ? browserLocale : defaultLocale;
  }
}

export default router;
