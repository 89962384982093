import { User } from '@supabase/supabase-js';
import { defineStore } from 'pinia';
import { supabase } from '@/supabase/init';

export const useAuthStore = defineStore('auth', {
    state: () => ({
        user: null as User | null,
        authInitialized: false
    }),
    actions: {
        setUser(payload: User | null) {
            this.user = payload;
        },
        async login(email: string, password: string) {

            const { data, error } = await supabase.auth.signInWithPassword({
                email,
                password,
            });

            if (error) throw error;

            this.setUser(data.user ?? null);
        },

        async logout() {
            const { error } = await supabase.auth.signOut();
            if (error) {
                console.log('Error logging out');
                return;
            };
            this.setUser(null);
        },

        async initializeAuth() {
            // Setup the listener for future auth state changes
            supabase.auth.onAuthStateChange((event, session) => {
                if (event === 'SIGNED_OUT') {
                    this.setUser(null);
                } else if (event === 'SIGNED_IN' && session) {
                    this.setUser(session.user);
                }
            });

            // if we already tried to initialize the auth we dont reinitialize it
            if (this.authInitialized) {
                return;
            }

            // Fetch the current user state
            const { data: { user } } = await supabase.auth.getUser();

            // Set the user state immediately based on the current user
            this.setUser(user);

            this.authInitialized = true;
        }
    },
});